








































































































































































































































































































































































































$elmHeight: 38px;
.tab-header {
  display: flex;
  flex-wrap: wrap;
  row-gap: 1rem;
  column-gap: 2rem;
  align-items: center;
  margin-bottom: 1rem;

  .form-group {
    margin: 0;
  }

  ::v-deep .btn {
    height: $elmHeight;
  }

  .date-range-wrapper {
    display: flex;
    width: 34rem;

    .form-control {
      width: 15rem;
      height: $elmHeight;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: none;
    }

    .btn-group {
      height: $elmHeight;

      > .btn:first-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}
